/* eslint-disable react/prop-types */
import React from 'react';
import {
  StylesProvider, createGenerateClassName, ThemeProvider,
} from '@material-ui/core';
import Menu from './menu';
import Footer from '../components/footer';
import '../styles/base.scss';
import { ParallaxProvider } from 'react-scroll-parallax';
import { createMuiTheme } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'iqcode',
});

const theme = createMuiTheme({
  palette: {
    primary: { main: '#03a9f4', contrastText: '#ffffff' },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, Roboto',
  },

  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
    },
  },

});

const Layout = ({ path, children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <Menu path={path} />

        {children}
        <Footer />
      </ParallaxProvider>
    </ThemeProvider>
  </StylesProvider>
);

export default Layout;
