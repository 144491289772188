import { Container, Grid } from '@material-ui/core';
import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocationIcon from '@material-ui/icons/LocationOn';
import EuroIcon from '@material-ui/icons/Euro';
import ScheduleIcon from '@material-ui/icons/Schedule';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const Footer = () => (
  <footer className="page-footer">
    <div className="overlay-holder">
      <div className="diagonal-overlay-light" />
      <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid container>
          <Grid item sm={12} md={7}>
            <h5>
              Contact
              {' '}
              <span>Info</span>
            </h5>
            <ul>
              <li>
                <MailIcon className="icon-spaced" />
                {' '}
                <a href="mailto:hello@iqcode.io">
                  hello@iqcode.io
                </a>
              </li>
              <li>
                <PhoneIcon className="icon-spaced" />
                {' '}
                <a href="tel:+48608775280">
                  +48 608 775 280
                </a>
              </li>
              <li>
                <ScheduleIcon className="icon-spaced" />
                {' '}
                mon - fri, 9am-6pm CET

              </li>
            </ul>
          </Grid>
          <Grid className="z-1" item sm={12} md={5}>
            <h5>
              Company
              {' '}
              <span>Data</span>
            </h5>
            <ul>
              <li>
                <AccountBalanceIcon className="icon-spaced" />
                {' '}
                IQcode Technologie Internetowe Paweł
                Kuffel
              </li>
              <li>
                <LocationIcon className="icon-spaced" />
                {' '}
                Chodkiewicza Street 7/22, 02-593 Warsaw
              </li>
              <li>
                <EuroIcon className="icon-spaced" />
                {' '}
                PL7811977904 (VAT EU)
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
    <div className="footer-copyright">
      <Container>
        © iqcode 2018-2022
        <a
          href="https://iqcode.pl"
          className="right"
          lang="pl-PL"
        >
          Polska wersja strony
          {' '}
          <OpenInNewIcon className="icon-spaced" />
        </a>
      </Container>
    </div>
  </footer>
);

export default Footer;
