const MenuLinks = [
  {
    to: '/',
    name: 'Start',
    full: 'Main page',
  },
  {
    to: '/mobile-apps/',
    name: 'Apps',
    full: 'Mobile Apps',
  },
  {
    to: '/web-development/',
    name: 'WEB',
    full: 'Web Development',
  },
  {
    to: '/progressive-web-apps/',
    name: 'PWA',
    full: 'Progressive Web Apps',
  },
  {
    to: '/backend-development/',
    name: 'SRV',
    full: 'Backend Development',
  },
  {
    to: '/tech-stack/',
    name: 'Tech',
    full: 'Our tech stack',
  },
  {
    to: '/devops/',
    name: 'DEVOPS',
    full: 'DevOps Services',
  },
  {
    to: '/consulting-it/',
    name: 'CONSULT',
    full: 'IT Consulting',
  },
  {
    to: '/contact/',
    name: 'CONTACT',
    full: 'Contact',
  },
];

export default MenuLinks;
