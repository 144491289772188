// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-single-jsx": () => import("./../../../src/layouts/blog-single.jsx" /* webpackChunkName: "component---src-layouts-blog-single-jsx" */),
  "component---src-layouts-tags-jsx": () => import("./../../../src/layouts/tags.jsx" /* webpackChunkName: "component---src-layouts-tags-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-backend-development-jsx": () => import("./../../../src/pages/backend-development.jsx" /* webpackChunkName: "component---src-pages-backend-development-jsx" */),
  "component---src-pages-consulting-it-jsx": () => import("./../../../src/pages/consulting-it.jsx" /* webpackChunkName: "component---src-pages-consulting-it-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-devops-jsx": () => import("./../../../src/pages/devops.jsx" /* webpackChunkName: "component---src-pages-devops-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-apps-jsx": () => import("./../../../src/pages/mobile-apps.jsx" /* webpackChunkName: "component---src-pages-mobile-apps-jsx" */),
  "component---src-pages-progressive-web-apps-jsx": () => import("./../../../src/pages/progressive-web-apps.jsx" /* webpackChunkName: "component---src-pages-progressive-web-apps-jsx" */),
  "component---src-pages-tech-stack-jsx": () => import("./../../../src/pages/tech-stack.jsx" /* webpackChunkName: "component---src-pages-tech-stack-jsx" */),
  "component---src-pages-web-development-jsx": () => import("./../../../src/pages/web-development.jsx" /* webpackChunkName: "component---src-pages-web-development-jsx" */)
}

